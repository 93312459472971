<template>
  <div id="component-list-shops">

    {{ /* AUTOCOMPLETE SHOP */ }}
    <div class="form-group mt-2">
      <div class="d-flex justify-content-center mt-4 mb-4">
        <b-form-radio
            class="ml-2"
            v-model="selectFilterShops"
            name="filter-radios"
            :value="false"
        >
          Magasins Journaliers
        </b-form-radio>
        <b-form-radio
            class="ml-2"
            v-model="selectFilterShops"
            name="filter-radios"
            :value="true"
        >
          Magasins Mensuel
        </b-form-radio>
        <b-form-radio
            class="ml-2"
            v-model="selectFilterShops"
            name="filter-radios"
            :value="null"
        >
          Tous les magasins
        </b-form-radio>
      </div>
      <AutoComplete
          :suggestions="listAutoCompleteSuggestions"
          :research-label="'magasin(s)'"
          :placeholder="'Trouvez un magasin'"
          entity="shop"
          @click-suggestion="clickSuggestionAutocomplete"
      />
    </div>

    {{ /* Badge autocomplete */ }}
    <div class="d-flex flex-wrap mb-4 w-100" v-if="listBadgeAutoComplete.length > 0">
      <div v-for="(searchObj, index) in listBadgeAutoComplete" :key="index">
        <b-badge class="p-2 c-pointer mt-2 ml-2" variant="dark" v-on:click="removeBadgeAutoComplete(searchObj)">
          {{ searchObj.result.name }} | {{ searchObj.result.center.name }}
          <b-icon
              class="ml-2"
              icon="x"
              font-scale="1.5"
              variant="light"
          >
          </b-icon>
        </b-badge>
      </div>
    </div>

    {{ /* VALIDATE SHOPS LIST */ }}
    <div class="d-flex justify-content-end">
      <button class="btn btn-info" v-on:click="emitSelectAllShops">Sélectionner tous les magasins</button>
    </div>
      <br>
    <div class="d-flex justify-content-end">
      <button class="btn btn-info" v-on:click="emitListShops">Valider la liste magasin</button>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  data() {
    return {
      listBadgeAutoComplete: [],
      selectFilterShops: null,
      listAutoCompleteSuggestions: []
    }
  },
  created() {
    this.$store.dispatch('fetchShops', {
      perPage: null,
      page: null
    })
        .then(() => {
          this.listAutoCompleteSuggestions = this.ShopModule.shops
        })
  },
  watch: {
    selectFilterShops(newValue) {
      this.selectFilterShops = newValue
      this.listAutoCompleteSuggestions = []
      if (this.selectFilterShops === true) {
        this.listAutoCompleteSuggestions = this.ShopModule.shops.filter(shop => shop.isMonth === true)
      } else if (this.selectFilterShops === false) {
        this.listAutoCompleteSuggestions = this.ShopModule.shops.filter(shop => shop.isMonth === false)
      } else {
        this.listAutoCompleteSuggestions = this.ShopModule.shops
      }
    }
  },
  methods: {
    /*-----------------AUTOCOMPLETE--------------------*/
    clickSuggestionAutocomplete(model) {
      let index = this.ShopModule.shops.findIndex(shop => shop === model.result.id)

      if (index > -1) {
        this.ShopModule.shops.splice(index, 1)
        this.listBadgeAutoComplete.splice(index, 1)
      } else {
        this.ShopModule.shops.push(model.result)
        this.listBadgeAutoComplete.push(model)
      }
    },
    removeBadgeAutoComplete(searchObj) {
      let index = this.ShopModule.shops.findIndex(shop => shop === searchObj.result.id)

      this.ShopModule.shops.splice(index, 1)
      this.listBadgeAutoComplete.splice(index, 1)
    },
    handleListBadgeAutoCompleteOnEdit(event) {
      let array = []
      if (event && event.shops) {
        event.shops.forEach(shop => {
          let model = {
            checked: PointerEvent,
            entity: "shop",
            result: shop
          }

          array.push(model)
        })
      } else {
        array = []
      }

      return array
    },
    /*----------------- EMIT LIST SHOPS --------------------*/
    emitListShops() {
      this.$emit('emit-list-shops', this.listBadgeAutoComplete)
    },
    emitSelectAllShops() {
      let badges = [];
      this.ShopModule.shops.forEach((shop) => {
        badges.push({checked: true, entity: 'shop', result: shop});
      })
      this.listBadgeAutoComplete = badges;
    }
  },
  computed: {
    ...mapState(['ShopModule']),
  },
  components: {
    AutoComplete: () => import('@/components/_shared/AutoComplete')
  }
}
</script>
